$redBci: var(--redBci);
$redLight: var(--redLight);
$redLightSoft: var(--redLightSoft);

$greyDefault: var(--greyDefault);
$greyMain: var(--greyMain);
$greyLighter: var(--greyLighter);
$greyLight: var(--greyLight);
$greyRegular: var(--greyRegular);
$greyCoolBci: var(--greyCoolBci);
$greyText: var(--greyText);
$greyTableStripped: var(--greyTableStripped);
$greyTableBorder: var(--greyTableBorder);
$green: var(--green);

$black: var(--black);

$blue: var(--blue);
$blueLight: var(--blueLight);

$tortoise: var(--tortoise);
$tortoiseLight: var(--tortoiseLight);

$white: var(--white);

// New Colors
$gray94: var(--gray94);
$newGray: var(--newGray);
$magenta: var(--magenta);
$bciBlue: var(--bciBlue);
$darkPurple: var(--darkPurple);
$lightPurple: var(--lightPurple);
$bciAqua10: var(--bciAqua10);
