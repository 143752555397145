@use "styles/variables" as vars;
@use "styles/colors" as colors;
@use "styles/tnlm-font";
@use "styles/custom.scss";
@use "styles/animate.min.scss";

// colors
:root {
  --redBci: #ff0000;
  --redLight: #ffe8e8;
  --redLightSoft: #fff0f1;

  --greyDefault: #f3f3f3;
  --greyMain: #b0b0b0;
  --greyLighter: #f9fafc;
  --greyLight: #f7f7f7;
  --greyRegular: #e0e0e0;
  --greyCoolBci: #808080;
  --greyText: #6a6a73;
  --greyTableStripped: #f7f8fa;
  --greyTableBorder: #ced3d8;
  --green: #0da738;

  --black: #454550;

  --blue: #215d95;
  --blueLight: #ecf7ff;

  --tortoise: #03989e;
  --tortoiseLight: #d8f4f5;

  --white: #fff;

  // New Colors
  --gray94: #f0f0f0;
  --newGray: #cac8c8;
  --magenta: #ff7676;
  --bciBlue: #0039ff;
  --darkPurple: #7b1fa2;
  --lightPurple: #f3e5f5;
  --bciAqua10: #e4f8f9;
}

// variables
:root {
  --breakpoint-mobile: 900;
}

body {
  margin: 0;
  font-family: vars.$font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: colors.$greyLight;
}

code {
  font-family: vars.$font-regular;
}
