@use "./variables" as vars;
@use "./colors" as colors;
// @use "./pages/searchResult";

@media print {
  *::-webkit-scrollbar {
    display: none;
  }
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

body {
  background-color: #f5f7fa !important;
}

mark {
  background-color: #ffecb3;
  padding: 0 2px;
}

.input-text-no-border {
  border: none;
  // border-bottom: 1px solid colors.$greyRegular;
  &:focus {
    // border: none;
    outline: none;
  }
  * {
    border: none !important;
  }
}

.card,
.btn {
  border-radius: vars.$border-radius-regular;
}

.scrollbar-style {
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #c8c6c4;
    border: 4px solid #fafafa;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #a1a09e;
  }
}
div.simplebar-scrollbar {
  &:before {
    background: #c8c6c4;
    left: 4px;
    right: 3px;
  }
  &.simplebar-visible {
    &:before {
      opacity: 1;
    }
  }
}

.tnlm-popover {
  border-radius: vars.$border-radius-regular;
  border: 1px solid colors.$greyRegular;
  background-color: colors.$white;
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.1);

  cursor: default;

  .tnlm-popover-header {
    color: colors.$redBci;
    font-weight: 600;
    padding: 12px;
    border-bottom: 1px solid colors.$greyRegular;
    display: flex;
    white-space: nowrap;
    .close-btn {
      display: flex;
      align-self: center;
      color: colors.$black;
      margin-left: auto;
      padding-left: 20px;
      cursor: pointer;
    }
  }
  .tnlm-popover-body {
    color: colors.$black;
    padding: 0 12px;
  }

  .tnlm-popover-footer {
    color: colors.$black;
    padding: 12px;
    border-top: 1px solid colors.$greyRegular;
  }
}
.box-icon {
  padding: 8px;
  border-radius: vars.$border-radius-regular;
  svg {
    width: 20px;
    height: 20px;
  }
}

.circle-icon {
  background-color: colors.$tortoiseLight;
  padding: 8px;
  border-radius: 50%;
}

.global-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: default;
}

.pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}

.is-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

a {
  text-decoration: none;
  color: colors.$blue;
  cursor: pointer;
}

a.black-linked {
  color: #454550;
  font-weight: bold;

  &.active-effect {
    &:visited {
      color: #808080;
      font-weight: normal;
      // h6,
      // .MuiButton-textPrimary {
      //   color: #808080 !important;
      // }
      .tnlm-font {
        color: #808080 !important;
      }
      .menu {
        .tnlm-font {
          color: #808080 !important;
        }
      }
    }
  }

  &:visited {
    color: #454550;
    font-weight: bold;
    .tnlm-font {
      color: #454550 !important;
    }
    .menu {
      .tnlm-font {
        color: #454550 !important;
      }
    }
  }
  &:hover {
    text-decoration: underline;
  }
}

a.blue-linked {
  color: colors.$bciBlue;

  &:visited {
    color: colors.$bciBlue;
  }
  &:hover {
    text-decoration: underline;
  }
}

a.link-underline {
  text-decoration: underline;
}

.no-border {
  border: none !important;
}
.version-cell {
  background: #e4f8f9;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 0.7em;
  font-weight: bold;
  padding: 2px 4px;
  display: block;
  text-align: center;
}

.latest-project-cell {
  background: #ffe8e8;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 11px;
  font-weight: normal;
  padding: 2px 4px;
  margin-left: 5px;
  color: #ff0000;
}

/**** HANDLE VISITED LINK ****/
a:visited {
  color: #333399;
  // h6,
  // .MuiButton-textPrimary {
  //   color: #333399 !important;
  // }
  // .tnlm-font {
  //   color: #333399 !important;
  // }
}
/**** END OF HANDLE VISITED LINK ****/

.MuiButton-contained {
  box-shadow: none !important;
}
.button-cancel {
  color: #454550 !important;
  background-color: transparent !important;
}

.input-switch {
  .MuiBox-root {
    margin-bottom: 8px;
  }
  .form-label {
    padding-top: 10px;
    margin-bottom: 0px;
  }
}

.bg-card {
  background-color: #fff;
}

.tnlm-alert {
  background-color: #f0f0f0;
  &.label {
    white-space: nowrap;
    line-height: 28px;
    border-radius: 10px;
    margin: 0 5px;
    font-size: 12px;
    color: #a0a0a0;
  }
  &.circle {
    margin-top: 2px;
    border: 1px solid rgba(27, 94, 32, 0.5);
    border-radius: 50%;
    height: 16px;
    width: 16px;
  }
  &.daily {
    background-color: #e8f5e9;
    &.label {
      color: #1b5e20;
    }
    &.circle {
      border: 1px solid rgba(27, 94, 32, 0.5);
    }
  }
  &.weekly {
    background-color: #e3f2fd;
    &.label {
      color: #0d47a1;
    }
    &.circle {
      border: 1px solid rgba(13, 71, 161, 0.5);
    }
  }
  &.monthly {
    background-color: #f3e5f5;
    &.label {
      color: #7b1fa2;
    }
    &.circle {
      border: 1px solid rgba(123, 31, 162, 0.5);
    }
  }
  &.without-alert {
    background: #ffebed;
    &.label {
      color: #ff0000;
    }
    &.circle {
      border: 1px solid colors.$redBci;
    }
  }
}

#mouse-over-popover {
  &.MuiPopover-root {
    background: none !important;
  }
}

.MuiDivider-root {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.show-companies-content {
  .MuiAccordion-root.Mui-expanded {
    margin: 16px 0 !important;
  }
}

.btn-icon.animated {
  bottom: 10px;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.btn-icon::before,
.btn-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 25px;
  width: 25px;
  margin-top: -2px;
  border-radius: 2px;
  background-size: 25px 25px;
  background-image: url(../assets/svg/arrow-down.svg);
}

hr {
  height: 1px;
  background-color: #e0e0e0;
  border: none;
}

/*** ESRI MAPS CUSTOM CSS ***/
.esri-widget {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300;
  font-size: 12px;
}
.esri-feature-content,
.esri-popup__content {
  a {
    color: #454550 !important;
  }
  .item-list-card {
    border: none !important;
    padding: 0 !important;
  }
  .item-list-card-title {
    font-size: 14px !important;
  }
  .item-list-card-title,
  .item-list-card-subtitle1,
  .item-list-card-subtitle2 {
    margin-bottom: 4px !important;
  }
  .tnlm-list-column .list-column-item {
    padding-top: 4px !important;
  }
  .btn-watchlist {
    width: 100%;
    display: block;
    padding: 10px;
    border: solid thin #ff0000;
    margin-top: 10px;
    border-radius: 4px;
    color: #ff0000;
    font-size: 12px;
    font-weight: bold;
    background-color: #fff;
    cursor: pointer;
    margin-bottom: 4px;
  }
  .btn-watchlist:hover {
    background-color: #ff0000;
    color: #fff;
  }
}
.esri-popup--shadow {
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2) !important;
}
#map-popup-description {
  padding: 0 !important;
  #map-view-popup {
    margin: 0 !important;
  }
}

.esri-legend {
  &.esri-widget,
  .esri-legend--card,
  .esri-legend--stacked,
  .esri-legend--card__section {
    width: 750px !important;
    height: 160px !important;
  }
  .esri-legend--card__carousel-title {
    margin-bottom: 10px !important;
  }
  .esri-legend--card__layer-caption {
    display: none;
  }
  .esri-legend--card__symbol-cell {
    svg {
      width: 35px;
      height: 35px;
      * {
        transform: scale(1.3);
      }
    }
  }
  .esri-legend--stacked,
  .esri-legend--card__section {
    min-height: 150px !important;
    height: auto !important;
  }
  .esri-legend--card__size-ramp-row {
    margin-bottom: 30px;
  }
  .esri-legend--card__label-container {
    flex-wrap: nowrap !important;
    .esri-legend--card__symbol-row {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-width: 120px;
    }
  }
  .esri-legend--card__layer-row:last-child {
    display: none !important;
  }
  .esri-legend--card__carousel-indicator-container {
    display: none !important;
  }
}
// .map-popup {
//     width: 80%;
//     height: 60vh;
// }
/*** END OF ESRI MAPS CUSTOM CSS ***/

.u-userLauncherColor,
.u-userLauncherColor:not([disabled]) {
  box-shadow:
    0px 2px 1px rgba(0, 0, 0, 0.05),
    1px 0px 3px rgba(0, 0, 0, 0.11) !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis-2-line {
  line-clamp: 1;
  -webkit-line-clamp: 1;
}

// media print
@media print {
  @page {
    size: auto;
    margin: 15mm 10mm 15mm 10mm;
  }
}

.label-radio-with-title {
  padding-top: 10px;
  font-size: 14px;
  color: #454550;
  .title {
    font-weight: 600;
  }
  .subtitle {
    padding-top: 4px;
    font-size: 13px;
  }
}

.esri-popup__header-title {
  color: #fff;
}

.esri-popup__header-container--button:hover {
  background: #fff !important;
}

.hide-fixed {
  position: fixed;
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  z-index: -100;
}

.label-bold {
  font-weight: bold;
}

.esri-view {
  height: 100%;
}

.esri-view-height-less-than-medium .esri-popup__main-container,
.esri-popup__main-container {
  max-height: none !important;
}

// Input placeholder
.MuiInputBase-root {
  input::placeholder,
  textArea::placeholder {
    opacity: 1;
    color: colors.$greyCoolBci;
  }
}

// Contact Status Styling
.contact-status-4 {
  margin-left: 5px;
  font-size: 12px;
  color: #808080;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

// Bid Price Styling
.bid-price {
  color: colors.$blue;
  font-size: 12px;
  position: relative;
  border-radius: 8px;
  background: rgba(12, 210, 210, 0.1);
  padding: 2px 7px;
  font-weight: bold;
  // top: -1px;
  display: inline-flex;
  align-items: center;
  .tnlm-font {
    color: colors.$blue !important;
    margin-right: 5px;
  }
}

.batch {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  padding: 2px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-weight: bold;
  .tnlm-icon {
    margin-right: 5px;
    padding: 5px;
    border-radius: 50%;
    overflow: hidden;
  }
  &.batch-completed {
    .tnlm-icon {
      background: colors.$green;
      .tnlm-font {
        color: #fff !important;
        font-size: 0.8em !important;
        font-weight: bold;
      }
    }
  }
  &.batch-overdue {
    .tnlm-font {
      color: colors.$redBci !important;
      font-size: 1em !important;
      font-weight: bold;
    }
  }
  &.batch-upcoming {
    .tnlm-font {
      color: colors.$green !important;
      font-size: 1em !important;
      font-weight: bold;
    }
  }
  &.batch-today {
    .tnlm-font {
      color: colors.$green !important;
      font-size: 1em !important;
      font-weight: bold;
    }
  }
}

.esri-basemap-toggle {
  width: 38px !important;
  height: 38px !important;
}
.esri-basemap-toggle__container,
.esri-basemap-toggle__image {
  width: 33px !important;
  height: 33px !important;
}

.MuiInputBase-input {
  &.Mui-disabled {
    &::placeholder {
      opacity: 0.6;
    }
  }
}

.MuiTypography-body1 {
  font-size: 12px;
  line-height: 16.06px;
  letter-spacing: 0.00938em;
}
.button-new-tab-icon {
  border: none;
  background: none;
  font-size: 1.125rem;
  padding: 3px;
  vertical-align: middle;
}
