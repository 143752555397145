@font-face {
  font-family: "tnlm-icon";
  src: url(tnlm-font/icon.eot?p3q0ct);
  src:
    url(tnlm-font/icon.eot?p3q0ct#iefix) format("embedded-opentype"),
    url(tnlm-font/icon.ttf?p3q0ct) format("truetype"),
    url(tnlm-font/icon.woff?p3q0ct) format("woff"),
    url(tnlm-font/icon.svg?p3q0ct#icon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.tnlm-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "tnlm-icon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.navigation-pointer:before {
    content: "\e9bc";
  }
  &.available:before {
    content: "\e900";
  }
  &.burger:before {
    content: "\e901";
  }
  &.diamond-fill:before {
    content: "\e902";
  }
  &.diamond-line:before {
    content: "\e903";
  }
  &.diamond-twins:before {
    content: "\e904";
  }
  &.add-new:before {
    content: "\e905";
  }
  &.admin:before {
    content: "\e906";
  }
  &.arrow-round-left:before {
    content: "\e907";
  }
  &.arrow-round-right:before {
    content: "\e908";
  }
  &.basic:before {
    content: "\e909";
  }
  &.bold:before {
    content: "\e90a";
  }
  &.building-element:before {
    content: "\e90b";
  }
  &.check-empty:before {
    content: "\e90c";
  }
  &.check-full:before {
    content: "\e90d";
  }
  &.contact-role:before {
    content: "\e90e";
  }
  &.contact:before {
    content: "\e90f";
  }
  &.custom-link:before {
    content: "\e910";
  }
  &.email-bounce:before {
    content: "\e911";
  }
  &.error-solid:before {
    content: "\e912";
  }
  &.export-files:before {
    content: "\e913";
  }
  &.feature:before {
    content: "\e914";
  }
  &.gavel:before {
    content: "\e915";
  }
  &.italic:before {
    content: "\e916";
  }
  &.lead-manager:before {
    content: "\e917";
  }
  &.live-tutorial:before {
    content: "\e918";
  }
  &.login:before {
    content: "\e919";
  }
  &.metric:before {
    content: "\e91a";
  }
  &.number:before {
    content: "\e91b";
  }
  &.order-list:before {
    content: "\e91c";
  }
  &.order-number:before {
    content: "\e91d";
  }
  &.paper-arrow-out-export:before {
    content: "\e91e";
  }
  &.policy:before {
    content: "\e91f";
  }
  &.progress:before {
    content: "\e920";
  }
  &.resolved:before {
    content: "\e921";
  }
  &.saved-search:before {
    content: "\e922";
  }
  &.strike-through:before {
    content: "\e923";
  }
  &.suggestion:before {
    content: "\e924";
  }
  &.survey-insight:before {
    content: "\e925";
  }
  &.survey:before {
    content: "\e926";
  }
  &.tutorial-checked:before {
    content: "\e927";
  }
  &.type:before {
    content: "\e928";
  }
  &.underlinev:before {
    content: "\e929";
  }
  &.user-enquiries:before {
    content: "\e92a";
  }
  &.user-happiness:before {
    content: "\e92b";
  }
  &.wont-research:before {
    content: "\e92c";
  }
  &.pipeline-empty:before {
    content: "\e92d";
  }
  &.pipeline-myfolder:before {
    content: "\e92e";
  }
  &.add-keyaccount:before {
    content: "\e92f";
  }
  &.add:before {
    content: "\e930";
  }
  &.alert-i:before {
    content: "\e931";
  }
  &.alert-list:before {
    content: "\e932";
  }
  &.alert-msg:before {
    content: "\e933";
  }
  &.alert-paper:before {
    content: "\e934";
  }
  &.all-menu:before {
    content: "\e935";
  }
  &.analytix:before {
    content: "\e936";
  }
  &.announcement:before {
    content: "\e937";
  }
  &.app:before {
    content: "\e938";
  }
  &.archive:before {
    content: "\e939";
  }
  &.arrow-back:before {
    content: "\e93a";
  }
  &.arrow-down:before {
    content: "\e93b";
  }
  &.arrow-left:before {
    content: "\e93c";
  }
  &.arrow-right:before {
    content: "\e93d";
  }
  &.arrow-up:before {
    content: "\e93e";
  }
  &.article:before {
    content: "\e93f";
  }
  &.bar-chart:before {
    content: "\e940";
  }
  &.bci-researcher:before {
    content: "\e941";
  }
  &.bci-text:before {
    content: "\e942";
  }
  &.bell-o:before {
    content: "\e943";
  }
  &.book-open:before {
    content: "\e944";
  }
  &.bookmark:before {
    content: "\e945";
  }
  &.briefcase:before {
    content: "\e946";
  }
  &.building:before {
    content: "\e947";
  }
  &.calendar:before {
    content: "\e948";
  }
  &.category:before {
    content: "\e949";
  }
  &.chart-line:before {
    content: "\e94a";
  }
  &.chart-pie:before {
    content: "\e94b";
  }
  &.chart:before {
    content: "\e94c";
  }
  &.clock:before {
    content: "\e94d";
  }
  &.close:before {
    content: "\e94e";
  }
  &.comparison-copy:before {
    content: "\e94f";
  }
  &.comparison:before {
    content: "\e950";
  }
  &.da-number:before {
    content: "\e951";
  }
  &.data-form:before {
    content: "\e952";
  }
  &.data:before {
    content: "\e953";
  }
  &.deal_data_field:before {
    content: "\e954";
  }
  &.development-type:before {
    content: "\e955";
  }
  &.dnd:before {
    content: "\e956";
  }
  &.document:before {
    content: "\e957";
  }
  &.dollar:before {
    content: "\e958";
  }
  &.download:before {
    content: "\e959";
  }
  &.eco:before {
    content: "\e95a";
  }
  &.enquiries:before {
    content: "\e95b";
  }
  &.eye-slashes:before {
    content: "\e95c";
  }
  &.eye:before {
    content: "\e95d";
  }
  &.files-o:before {
    content: "\e95e";
  }
  &.files:before {
    content: "\e95f";
  }
  &.floor-area:before {
    content: "\e960";
  }
  &.folder:before {
    content: "\e961";
  }
  &.follow-up-complete:before {
    content: "\e962";
  }
  &.followup-today:before {
    content: "\e963";
  }
  &.fullpage-exit:before {
    content: "\e964";
  }
  &.fullpage:before {
    content: "\e965";
  }
  &.government-id:before {
    content: "\e966";
  }
  &.green:before {
    content: "\e967";
  }
  &.group-companies:before {
    content: "\e968";
  }
  &.help-circle:before {
    content: "\e969";
  }
  &.home-o:before {
    content: "\e96a";
  }
  &.image:before {
    content: "\e96b";
  }
  &.info-o:before {
    content: "\e96c";
  }
  &.keyaccount:before {
    content: "\e96d";
  }
  &.leaderboard:before {
    content: "\e96e";
  }
  &.link:before {
    content: "\e96f";
  }
  &.location-point:before {
    content: "\e970";
  }
  &.lock:before {
    content: "\e971";
  }
  &.mail:before {
    content: "\e972";
  }
  &.map-o:before {
    content: "\e973";
  }
  &.map-pin:before {
    content: "\e974";
  }
  &.map:before {
    content: "\e975";
  }
  &.master-plan:before {
    content: "\e976";
  }
  &.miniCompany:before {
    content: "\e977";
  }
  &.more-app:before {
    content: "\e978";
  }
  &.next:before {
    content: "\e979";
  }
  &.non-bell-o:before {
    content: "\e97a";
  }
  &.notification-active:before {
    content: "\e97b";
  }
  &.notification-o:before {
    content: "\e97c";
  }
  &.notification:before {
    content: "\e97d";
  }
  &.open-in-new:before {
    content: "\e97e";
  }
  &.option:before {
    content: "\e97f";
  }
  &.other:before {
    content: "\e980";
  }
  &.ownership-type:before {
    content: "\e981";
  }
  &.pen:before {
    content: "\e982";
  }
  &.person-group:before {
    content: "\e983";
  }
  &.person:before {
    content: "\e984";
  }
  &.phone:before {
    content: "\e985";
  }
  &.place-o:before {
    content: "\e986";
  }
  &.place:before {
    content: "\e987";
  }
  &.plus_circle:before {
    content: "\e988";
  }
  &.postcode-o:before {
    content: "\e989";
  }
  &.prev:before {
    content: "\e98a";
  }
  &.printer:before {
    content: "\e98b";
  }
  &.project-id:before {
    content: "\e98c";
  }
  &.project:before {
    content: "\e98d";
  }
  &.projectpipeline:before {
    content: "\e98e";
  }
  &.related-contact:before {
    content: "\e98f";
  }
  &.relationship-mapping:before {
    content: "\e990";
  }
  &.researcher-note:before {
    content: "\e991";
  }
  &.researcher-remark:before {
    content: "\e992";
  }
  &.role:before {
    content: "\e993";
  }
  &.search:before {
    content: "\e994";
  }
  &.setting:before {
    content: "\e995";
  }
  &.share:before {
    content: "\e996";
  }
  &.sheet:before {
    content: "\e997";
  }
  &.sign-out:before {
    content: "\e998";
  }
  &.site-area:before {
    content: "\e999";
  }
  &.site-unit:before {
    content: "\e99a";
  }
  &.site:before {
    content: "\e99b";
  }
  &.sort-table-asc:before {
    content: "\e99c";
  }
  &.sort-table-desc:before {
    content: "\e99d";
  }
  &.sort-table:before {
    content: "\e99e";
  }
  &.sort:before {
    content: "\e99f";
  }
  &.stage:before {
    content: "\e9a0";
  }
  &.status:before {
    content: "\e9a1";
  }
  &.storey:before {
    content: "\e9a2";
  }
  &.strip:before {
    content: "\e9a3";
  }
  &.subfolder-o:before {
    content: "\e9a4";
  }
  &.task-all:before {
    content: "\e9a5";
  }
  &.task-complete:before {
    content: "\e9a6";
  }
  &.task-o:before {
    content: "\e9a7";
  }
  &.task-overdue:before {
    content: "\e9a8";
  }
  &.task-today:before {
    content: "\e9a9";
  }
  &.task-upcoming:before {
    content: "\e9aa";
  }
  &.task:before {
    content: "\e9ab";
  }
  &.telephone:before {
    content: "\e9ac";
  }
  &.tender-status:before {
    content: "\e9ad";
  }
  &.tick-mark:before {
    content: "\e9ae";
  }
  &.total-matched-project:before {
    content: "\e9af";
  }
  &.town:before {
    content: "\e9b0";
  }
  &.trash:before {
    content: "\e9b1";
  }
  &.trophy:before {
    content: "\e9b2";
  }
  &.user-check:before {
    content: "\e9b3";
  }
  &.user-o:before {
    content: "\e9b4";
  }
  &.user-plus:before {
    content: "\e9b5";
  }
  &.version-o:before {
    content: "\e9b6";
  }
  &.version:before {
    content: "\e9b7";
  }
  &.view-card:before {
    content: "\e9b8";
  }
  &.view-table:before {
    content: "\e9b9";
  }
  &.website:before {
    content: "\e9ba";
  }
  &.working-with:before {
    content: "\e9bb";
  }
}
